<template>
  <v-dialog
    v-model="dialogCancelarVenda"
    @click:outside="$emit('update:dialogCancelarVenda', false)"
    @keydown.esc="$emit('update:dialogCancelarVenda', false)"
    width="500"
  >
    <v-card>
      <v-card-title>
        <v-icon left>mdi-alert</v-icon>
        {{ $tc("global.cancelar") }}
      </v-card-title>
      <v-card-text>
        Deseja cancelar esta venda?
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="error"
          text
          @click="$emit('update:dialogCancelarVenda', false)"
        >
          {{ $tc("global.voltar") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          small
          color="red"
          class="white--text"
          @click.stop="deletarVenda()"
        >
          <v-icon left>mdi-delete</v-icon>
          {{ $tc("global.sim") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { cancelVenda, cancelVendaFinalizada } from "@/api/vendas/vendas.js";
export default {
  name: "DialogDeletarVenda",

  props: {
    dialogCancelarVenda: {
      type: Boolean,
      default: false,
    },
    venda_id: {
      type: Number,
    },
    venda_finalizada: {
      type: Boolean,
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    deletarVenda() {
      this.loading = true;
      if (this.venda_finalizada){
        cancelVendaFinalizada(this.venda_id)
          .then((response) => {
            if (response.status === 200) {
              this.$emit("fetch-vendas");
              this.$emit("update:dialogCancelarVenda", false);
              this.loading = false;
            }
          })
          .catch(() => {
            this.$toast("global.errordel");
            this.loading = false;
          });
      } else {
        cancelVenda(this.venda_id)
          .then((response) => {
            if (response.status === 200) {
              this.$emit("fetch-vendas");
              this.$emit("update:dialogCancelarVenda", false);
              this.loading = false;
            }
          })
          .catch(() => {
            this.$toast("global.errordel");
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style></style>
